import auth from "@/utils/auth.js";
import Tools from '@/utils/tools.js';
import axios from 'axios';
import Qs from 'qs';
import kayak from '@/frame/kayak.js'

let httpUtil = {};
let diskThrough="1,3";

// const hlsPath="http://172.23.6.11:1882/apis/hls/";
// const uploadPath = "http://172.23.6.11:1882/";
// const basePath = "http://localhost:38080/";

// const hlsPath="http://172.23.6.11:1882/apis/hls/";
// const uploadPath = "http://172.23.6.11:1882/";
// const basePath = "https://wxoa.test.kkws.cn/";

const hlsPath=diskThrough=='1,3'?"https://fileapi.kkws.cn/hls/":"https://fileapi.kkws.cn/apis/hls/"
const basePath = "https://wxoa.kayakwise.com/";
const uploadPath = diskThrough=='1,3'?"https://fileapi.kkws.cn/":"https://fileapi.kkws.cn/";

const downloadPath="http://127.0.0.1:18099/api/download/";


const kkwsPath="http://127.0.0.1:58001/"

var openWindow;

httpUtil.diskThrough=diskThrough
httpUtil.basePath = basePath;
httpUtil.uploadPath = uploadPath;
httpUtil.hlsPath=hlsPath
httpUtil.downloadPath=downloadPath

httpUtil.comnUpdate = function(data) {
	let action = data.action;
	if (!action || action.indexOf(".") == -1) {
		return;
	}
	if (data.successAlert !== false) {
		data.successAlert = true;
	}
	let actions = action.split(".");
	data.url = "commUpdate/" + actions[0] + "/" + actions[1] + ".json";

	return httpUtil.ajax(data);
};

httpUtil.update = function(data) {
	if (data.successAlert !== false) {
		data.successAlert = true;
	}
	return httpUtil.ajax(data);
};

httpUtil.query = function(data) {
	data.mask = false;
	return httpUtil.ajax(data);
};

httpUtil.graphqlQurey = function(data) {
	let graphql = data.graphql;
	if (data.params) {
		let graphqlFirst = graphql.substring(0, graphql.indexOf(")"));
		let graphqlSecond = graphql.substring(graphql.indexOf(")"));
		for (var key in data.params) {
			if (!data.params[key] && data.params[key] !== 0) {
				continue;
			}
			if (typeof data.params[key] == "string") {
				graphqlFirst += "," + key + ": \"" + Tools.enterConvert(data.params[key], data.replaceQuotation) +
					"\"";
			} else {
				graphqlFirst += "," + key + ":" + data.params[key];
			}
		}
		graphql = graphqlFirst + graphqlSecond;
	}

	data.params = graphql;

	data.url = "graphql.json";
	data.mask = false;
	return httpUtil.ajaxJson(data);
};

httpUtil.graphqlUpdate = function(data) {
	let graphql = data.graphql;

	if (data.params) {
		let graphqlFirst = graphql.substring(0, graphql.indexOf(")"));
		let graphqlSecond = graphql.substring(graphql.indexOf(")"));
		for (var key in data.params) {
			if (!data.params[key] && data.params[key] !== 0) {
				continue;
			}
			if (typeof data.params[key] == "string") {
				graphqlFirst += "," + key + ": \"" + Tools.enterConvert(data.params[key], data.replaceQuotation) +
					"\"";
			} else {
				graphqlFirst += "," + key + ":" + data.params[key];
			}
		}
		graphql = graphqlFirst + graphqlSecond;
	}

	data.params = graphql;

	data.url = "graphql.json";
	data.mask = false;
	data.successAlert = true;
	return httpUtil.ajaxJson(data);
};

httpUtil.comnQuery = function(data) {
	let action = data.action;

	if (!action || action.indexOf(".") == -1) {

		return;
	}

	let actions = action.split(".");

	data.url = "commQuery/" + actions[0] + "/" + actions[1] + ".json";
	data.mask = false;
	return httpUtil.ajax(data);
};

httpUtil.comnQueryTree = function(data) {
	let action = data.action;
	if (!action || action.indexOf(".") == -1) {
		return;
	}
	let actions = action.split(".");
	data.url = "commTreeQuery/" + actions[0] + "/" + actions[1] + ".json";
	return httpUtil.ajax(data);
};

const return_code = '0000'

httpUtil.ajax = function(data) {

	return new Promise((resolve, reject) => {
		let url=""
		if(data.url.indexOf("http")>-1){
			url=data.url
		}else{
			url=basePath + data.url
		}
		axios({
			method: data.type?data.type:"POST",
			url: url,
			data: Qs.stringify(data.params),
			headers: {
				'Content-type': 'application/x-www-form-urlencoded',
				'Authorization': auth.getToken()
			}
		}).then(res => {
			let reData = res.data;
			if (data.callback) {
				data.callback(reData);
			}

			if (reData.success || reData.status === '200' || reData.rtnCode === return_code || reData.rows||reData.data || reData.code === 200) {
				if (reData.rtnCode) {
					Tools.alert(reData.rtnDesc || "操作成功");
				} else if (data.successAlert) {
					Tools.alert(reData.returnmsg || "操作成功");
				}

				if (data.dataAfterSuccess) {
					data.dataAfterSuccess(reData);
				}
				resolve(reData);
			} else {
				if (reData.login) {
					kayak.app.$router.push({
						path: '/login'
					});
					return;
				}
				if (reData.token_freshen) {
					auth.setToken(reData.token);
					httpUtil.ajax(data).then(_data => {
						resolve(_data);
					});
					return;
				}
				if (data.errCallback) {
					data.errCallback(reData);
				} else {
					Tools.alert(reData.returnmsg || reData.rtnDesc || data.errorMsg||"操作失败", "danger");
				}
			}
		}).catch(function(err) {
			if (data.callback) {
				data.callback(err);
			}
			Tools.alert(data.errorMsg||"服务器异常，请稍后尝试", "danger");
			console.log(err);
			reject(err)

		});
	});
};

httpUtil.ajaxKKWS=function(data){
	return new Promise((resolve, reject) => {
		axios({
			method: data.type,
			url: data.basePath?data.basePath+data.url:kkwsPath + data.url,
			data:Qs.stringify(data.params),
			headers: {
				'Content-type': 'application/x-www-form-urlencoded',
				'Authorization': auth.getToken()
			}
		}).then(res => {
			let reData = res.data;
			resolve(reData)
		}).catch(function(err) {
			reject(err)
		});
	});
}





httpUtil.ajaxJson = function(data) {
	return new Promise((resolve, reject) => {
		axios({
			method: "POST",
			url: basePath + data.url,
			data: data.params,
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				'Authorization': auth.getToken()
			}
		}).then(res => {
			let reData = res.data;
			if (data.callback) {
				data.callback(reData);
			}
			if (reData.success || reData.rtnCode === return_code || reData.rows) {
				if (reData.rtnCode) {
					Tools.alert(reData.rtnDesc || "操作成功");
				} else if (data.successAlert) {
					Tools.alert(reData.returnmsg || "操作成功");
				}
				if (data.dataAfterSuccess) {
					data.dataAfterSuccess(reData);
				}
				resolve(reData);
			} else {
				if (reData.login) {
					kayak.app.$router.push({
						path: '/login'
					});
					return;
				}

				if (reData.token_freshen) {
					auth.setToken(reData.token);
					httpUtil.ajaxJson(data).then(_data => {
						resolve(_data);
					});
					return;
				}

				if (data.errCallback) {
					data.errCallback(reData);
				} else {
					Tools.alert(reData.returnmsg || reData.rtnDesc || "操作失败", "danger");
				}
			}
		}).catch(function(err) {
			Tools.alert(reData.returnmsg || "服务器异常，请稍后尝试", "danger");
			console.log(err);
		});
	});
};


httpUtil.download = function(data, fileName) {

	let _ajaxUrl;
	if (data.url.indexOf("http") == -1) {
		_ajaxUrl = basePath + data.url;
	} else {
		_ajaxUrl = data.url;
	}

	if (!data.params) {
		data.params = {}
	}

	axios({
		method: 'post',
		url: _ajaxUrl,
		data: Qs.stringify(data.params),
		responseType: 'blob',
		headers: {
			'Content-type': 'application/x-www-form-urlencoded',
			'Authorization': auth.getToken()
		}
	}).then(response => {
		if (data.callback) {
			data.callback(response);
		}
		if (!response) {
			return
		}

		console.log(response)

		let url = window.URL.createObjectURL(new Blob([response.data]));
		if(typeof ipcRenderer != "undefined"){
			ipcRenderer.send("download-excel",url,fileName)
			return;
		}
		let link = document.createElement('a');
		link.style.display = 'none';
		link.href = url;
		link.setAttribute('download', fileName);

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

	}).catch((error) => {
		if (data.callback) {
			data.callback();
		}
		console.info(error);
	})
};

httpUtil.downloadBlob = function(data,fileName){
	return new Promise((resolve, reject) =>{

		let _ajaxUrl;
		if (data.url.indexOf("http") == -1) {
			_ajaxUrl = basePath + data.url;
		} else {
			_ajaxUrl = data.url;
		}

		if (!data.params) {
			data.params = {}
		}

		axios({
			method: 'post',
			url: _ajaxUrl,
			data: Qs.stringify(data.params),
			responseType: 'blob',
			headers: {
				'Content-type': 'application/x-www-form-urlencoded',
				'Authorization': auth.getToken()
			}
		}).then(response => {
			if (data.callback) {
				data.callback(response);
			}
			if (!response) {
				return
			}

			resolve(response)

		}).catch((error) => {
			reject(error)
		})
	})
};

httpUtil.sysparam = function(paraid, moduleid = '0') {
	return new Promise((resolve, reject) => {
		httpUtil.comnQuery({
			action: "SystemParam.find",
			params: {
				moduleid: moduleid,
				paraid: paraid,
			}
		}).then(data => {
			if (data.rows && data.rows[0]) {
				resolve(data.rows[0].paravalue);
			} else {
				resolve();
			}
		}).catch(e => {
			reject(e);
		})
	});
}

httpUtil.dictCache = {};

httpUtil.dict = function(dict) {
	return new Promise((resolve, reject) => {
		let dt = httpUtil.dictCache[dict];
		if (dt == null) {
			let graphql =
				'{queryDictItem(action:"listDictItems"){rows{dict,itemkey,itemval,itemorder},results}}'
			httpUtil.graphqlQurey({
				graphql: graphql,
				params:{
					dict:dict
				}
			}).then(data => {
				httpUtil.dictCache[dict] = data["queryDictItem"].rows;
				return resolve(data["queryDictItem"].rows);
			});
		} else {
			return resolve(dt);
		}
	});
}

httpUtil.dictTransfer = function(dict, key) {
	return new Promise((resolve, reject) => {
		if (dict === null || dict === '' || key === null || key === '') {
			return resolve('');
		}
		httpUtil.dict(dict).then(rows => { //取得字典数据
			if (!rows || rows.length == 0) { //取不到字典数据
				return key;
			}

			let vals = [];
			let keys = String(key).split(',');
			let len = keys.length;

			keys.map(key => {
				let value = "";
				rows.map(row => {
					if (row.itemkey == key) {
						value = row.itemval;
					}
				});
				vals.push(value);
			});

			return resolve(vals.join('，'));
		});
	});
}

export default httpUtil;
