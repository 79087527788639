<template>
    <div class="full-page" :class="{ 'nav-open': $sidebar.showSidebar }">
        <md-toolbar md-elevation="0" class="md-transparent md-toolbar-absolute">
            <md-icon md-src="/static/svg/login/kayak.svg" style="width:220px;height:36px;margin:0;margin-top: 10px;">
            </md-icon>
            <!-- <img :src="loginConfig.companyLogo" style="width:220px;height:36px;margin-left:21px;margin-top:10px;"> -->
        </md-toolbar>
        <div class="wrapper wrapper-full-page">
            <div class="page-header header-filter" :class="setPageClass" filter-color="black" :style="setBgImage">
                <div class="container md-offset">
                    <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
                        <LoginBox :loginConfig="loginConfig"></LoginBox>
                    </zoom-center-transition>
                </div>
                <footer class="footer">
                    <div class="container md-offset">
                        <div>北京开科唯识技术股份有限公司</div>
                        <div class="copyright text-center">
                            <!--							Copyright © 2020 kayak.All rights reserved.-->
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</template>
<script>
    import LoginBox from "./LoginBox";
    import auth from "@/utils/auth.js";
    export default {
        components: {
            LoginBox
        },
        props: {
            backgroundColor: {
                type: String,
                default: "black"
            }
        },
        inject: {
            autoClose: {
                default: true
            }
        },
        data() {
            return {
                responsive: false,
                showMenu: false,
                menuTransitionDuration: 250,
                pageTransitionDuration: 300,
                year: new Date().getFullYear(),
                loginConfig: {}
            };
        },
        computed: {
            setBgImage() {
                let images = {
                    LoginBox: "/static/images/1.jpg"
                };
                return {
                    backgroundImage: `url(${images['LoginBox']})`
                };
            },
            setPageClass() {
                return `Login-page`.toLowerCase();
            }
        },
        created() {

        },
        methods: {

        }
    };
</script>
<style lang="scss" scoped>
    $scaleSize: 0.1;
    $zoomOutStart: 0.7;
    $zoomOutEnd: 0.46;

    @keyframes zoomIn8 {
        from {
            opacity: 0;
            transform: scale3d($scaleSize, $scaleSize, $scaleSize);
        }

        100% {
            opacity: 1;
        }
    }

    .wrapper-full-page .zoomIn {
        animation-name: zoomIn8;
    }

    @keyframes zoomOut8 {
        from {
            opacity: 1;
            transform: scale3d($zoomOutStart, $zoomOutStart, $zoomOutStart);
        }

        to {
            opacity: 0;
            transform: scale3d($zoomOutEnd, $zoomOutEnd, $zoomOutEnd);
        }
    }

    .wrapper-full-page .zoomOut {
        animation-name: zoomOut8;
    }

    @media (min-width: 576px) {
        .md-offset {
            max-width: 576px;
        }
    }

    @media (min-width: 768px) {
        .md-offset {
            max-width: 768px;
        }
    }

    @media (min-width: 992px) {
        .md-offset {
            max-width: 992px;
        }
    }

    @media (min-width: 1200px) {
        .md-offset {
            max-width: 1200px;
        }
    }

    .header-filter::before {
        background: transparent;
    }
</style>
