<template>
  <div>
    <el-upload
            class="upload-demo"
            ref="upload"
            :value="value"
            :headers="headers"
            :action="dataUploadUrl"
            :disabled="dataDisabled === true || dataDisabled === 'true'"
            :limit="dataLimit"
            :drag="dataDrag === true || dataDrag === 'true'"
            :list-type="dataType"
            :accept="dataAccept"
            :multiple="dataMultiple === true || dataMultiple === 'true'"
            :data="extraData"
            :auto-upload="dataAutoUpload === true || dataAutoUpload === 'true'"
            :on-preview="dataPreview"
            :on-remove="dataRemove"
            :on-success="_dataSuccess"
            :on-error="dataError"
            :on-progress="dataProgress"
            :on-change="dataChange"
            :before-upload="dataBeforeUpload"
            :before-remove="dataBeforeRemove"
            :on-exceed="dataExceed"
    >
      <i class="el-icon-upload" v-if="dataDrag"></i>
      <div class="el-upload__text" v-if="dataDrag">将文件拖到此处，或<em>点击上传</em></div>
      <k-btn class="md-success" v-else>
        <md-icon md-src="/static/svg/upload.svg"/>
        上传附件
      </k-btn>
    </el-upload>
    <!-- 进度显示 -->
    <div class="progress-box" v-if="isUploading">
      <span>{{ isParseFile ? '文件解析中...' : '上传进度：' + percent.toFixed() + '%' }}</span>
      <k-btn class="md-danger md-sm" v-if="showJixu" :data-handler="sendRequest">继续</k-btn>
    </div>
  </div>
</template>

<script>
  import props from "@/components/k-element/common/k-field-props.js";
  import event from "@/components/k-element/common/k-field-event.js";
  import emitter from "@/components/k-element/common/k-emitter.js";
  import auth from "@/utils/auth.js";
  import Tools from '../../../utils/tools';
  import SparkMD5 from "spark-md5";


  export default {
    name: 'KFieldUpload',
    filters: {
      btnTextFilter(val) {
        return val ? '暂停' : '继续'
      }
    },
    mixins: [props(), event(), emitter()],
    data() {
      return {
        uploadSuccessList: [],
        fileList:[],
        extraData: {},
        headers: {
          'Authorization': auth.getToken()
        },
        percent: 0,
        percentCount: 0,
        currentFid: '',
        currentFname: '',
        isParseFile: false,
        isUploading: false,
        currentFile: null,
        uploadParams: {},
        showJixu:false
      }
    },
    props: {
      dataUploadUrl: {
        type: String,
        default: 'base/comn-upload.json'
      },
      dataType: {
        type: String,
        default: 'text'
      },
      dataDrag: {
        type: [Boolean, String],
        default: true
      },
      dataAccept: String,
      dataDisabled: {
        type: [Boolean, String],
        default: false
      },
      dataLimit: {
        type: Number,
        default: 1
      },
      dataMultiple: {
        type: [Boolean, String],
        default: true
      },
      dataAutoUpload: {
        type: [Boolean, String],
        default: false
      },
      dataPreview: {
        type: Function
      },
      dataRemove: {
        type: Function
      },
      dataSuccess: {
        type: Function
      },
      dataError: {
        type: Function
      },
      dataProgress: {
        type: Function
      },
      dataBeforeUpload: {
        type: Function
      },
      dataBeforeRemove: {
        type: Function
      },
      dataExceed: {
        type: Function
      },
      // simple or slice
      dataUploadType: {
        type: String,
        default: 'simple'
      },
      // slice upload request url prefix
      dataUploadPathPrefix: {
        type: String,
        default: ''
      },
    },
    computed: {
      _dataUploadUrl() {
        return this.httpUtil.basePath + this.dataUploadUrl;
      },
      loginUser() {
        return this.$store.state.loginUser
      },
    },
    methods: {
      validate(){
        if(this.dataAllowblank===false){
          if(this.fileList.length===0){
            return "请选择文件上传"
          }
        }
        return true
      },
      //外部可调用函数
      //调用方式: this.$refs.xx.method()
      upload(params) {
        //上传函数
        this.extraData = params
        this.$nextTick(()=>{
          if (this.dataUploadType === 'slice') {
            this.sliceUpload()
          } else {
            this.$refs.upload.submit()
          }
        })
      },
      getSuccessUpload() {
        //获取成功上传的文件列表
        console.log(this.uploadSuccessList)
      },
      dataChange(file, fileList){
        this.fileList=fileList
        this.currentFile=file
      },
      async sliceUpload() {
        let file = this.currentFile
        this.isUploading = true
        this.isParseFile = true
        this.currentFname = file.name
        this.currentFid ="Fid"+Tools.uuid()
        if (!file) return
        this.percent = 0
        // 获取文件并转成 ArrayBuffer 对象
        const fileObj = file.raw
        let buffer
        try {
          buffer = await this.fileToBuffer(fileObj)
        } catch (e) {
          console.error(e)
        }

        // 将文件按固定大小（4M）进行切片，注意此处同时声明了多个常量
        const chunkSize = 4 * 1024 * 1024
        // 保存所有切片的数组
        const chunkList = []
        // 计算总共多个切片
        const chunkListLength = Math.ceil(fileObj.size / chunkSize)

        console.log('计算总共多个切片', chunkListLength);

        // 根据文件内容生成 hash 值
        const spark = new SparkMD5.ArrayBuffer()
        spark.append(buffer)
        const hash = spark.end()

        // 生成切片，这里后端要求传递的参数为字节数据块（chunk）和每个数据块的文件名（fileName）
        let curChunk = 0 // 切片时的初始位置
        let sliceData = ''
        for (let i = 0; i < chunkListLength; i++) {
          let chunk=fileObj.slice(curChunk, curChunk + chunkSize)
          const item = {
            index: i + 1,
            size: chunk.size
          }
          // 计算切片MD5
          let sliceBuffer = null
          try {
            sliceBuffer = await this.fileToBuffer(chunk)
          } catch (e) {
            console.error(e)
          }
          // 根据文件内容生成 hash 值
          item.fmd5 = new SparkMD5.ArrayBuffer().append(sliceBuffer).end()

          // to Base64
          item.base64 = await this.blobToBase64(chunk)
          item.success=false
          curChunk += chunkSize
          chunkList.push(item)
          sliceData += `${item.index} ${item.fmd5} ${chunk.size}\n`
        }
        sliceData = sliceData.substring(0, sliceData.length - 1)
        this.chunkList = chunkList // sendRequest 要用到
        this.hash = hash // sendRequest 要用到
        this.isParseFile = false
        // 上传文件md5列表信息
        let resPushMd5 = await this.sendMd5Slices(sliceData)
        console.log('上传文件md5列表信息', resPushMd5);
        // 上传切片
        this.sendRequest()
      },
      sendMd5Slices(sliceData) {
        return this.httpUtil.ajax({
          url: this.dataUploadPathPrefix + 'push/slices',
          params: Object.assign({ 'fid': this.currentFid, sliceData: sliceData }, this.extraData),
          errorMsg: "上传文件md5列表信息失败"
        })
      },
      blobToBase64(blob) {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = (e) => {
            let result = e.target.result
            resolve(result.substring(result.indexOf(',') + 1));
          };
          // readAsDataURL
          fileReader.readAsDataURL(blob);
          fileReader.onerror = () => {
            reject(new Error('blobToBase64 error'));
          };
        });
      },
      // 上传切片
      sendRequest() {
        this.showJixu=false
        const requestList = [] // 请求集合
        this.chunkList.forEach((item, index) => {
          if(item.success==false){
            const fn = () => {
              return this.httpUtil.ajax({
                url: this.dataUploadPathPrefix + 'upload/slice',
                params: Object.assign({
                  fid: this.currentFid,
                  fname: this.currentFname,
                  index: item.index,
                  fmd5: item.fmd5,
                  size: item.size,
                  data: item.base64,
                  user: this.loginUser.employee_no,
                }, this.extraData)
              }).then(res => {
                if (res.code === 200) { // 成功
                  item.success=true
                  if (this.percentCount === 0) { // 避免上传成功后会删除切片改变 chunkList 的长度影响到 percentCount 的值
                    this.percentCount = 100 / this.chunkList.length
                  }
                  this.percent += this.percentCount // 改变进度
                }
              }).catch(e=>{

              })
            }
            requestList.push(fn)
          }
        })

        let i = 0 // 记录发送的请求个数
        // 文件切片全部发送完毕后，需要请求 '/merge' 接口，把文件的 hash 传递给服务器
        const complete = () => {
          let isVideos=Tools.isVideos(this.currentFname)
          setTimeout(this.httpUtil.ajax({
            url: this.dataUploadPathPrefix + 'check/slice',
            params: Object.assign({ fid: this.currentFid,isVideos:isVideos?"1":"0" }, this.extraData)
          }).then(res => {
            if(res.msg){
              this.showJixu=true
            }else{
              this._dataSuccess(res, this.currentFile, this.fileList)
            }
          }),1000)
        }
        const send = async () => {
          if (i >= requestList.length) {
            // 发送完毕
            complete()
            return
          }
          try {
            await requestList[i]()
          }catch (e) {

          }
          i++
          send()
        }
        send() // 发送请求
      },
      // 将 File 对象转
      // ArrayBuffer
      fileToBuffer(file) {
        return new Promise((resolve, reject) => {
          const fr = new FileReader()
          fr.onload = e => {
            resolve(e.target.result)
          }
          fr.readAsArrayBuffer(file)
          fr.onerror = () => {
            reject(new Error('转换文件格式发生错误'))
          }
        })
      },
      doReset() {
        //重置上传控件，清空上传列表
        this.uploadSuccessList = []
        this.extraData = {}
        this.$refs.upload.clearFiles()
      },
      abort(file) {
        //取消上传
        this.$refs.upload.abort(file)
      },
      _dataSuccess(response, file, fileList) {
        if (response.success === true||response.data==="success"||response.code===200) {
          this.uploadSuccessList = fileList
          Tools.alert("上传成功")
          if (this.dataSuccess) {
            this.dataSuccess(file, fileList)
          }
          this.$emit("input", fileList)
        } else {
          Tools.alert("上传失败", "danger")
        }
      },
    },
  }
</script>
<style scoped>
  .progress-box {
    box-sizing: border-box;
    width: 360px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 8px 10px;
    background-color: #ecf5ff;
    font-size: 14px;
    border-radius: 4px;
  }
</style>