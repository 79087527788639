<template>
	<div class="md-layout text-center">
		<div class="login-content md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
			<login-card header-color="green">
				<h4 slot="title" class="title" style="font-size: 18px;font-weight: initial;">看板</h4>
				<md-button slot="buttons" class="md-just-icon md-simple md-white"
					style="margin-right: 30px !important;">
					<md-icon md-src="/static/svg/login/cmmi.svg"></md-icon>
				</md-button>
				<md-button slot="buttons" class="md-just-icon md-simple md-white"
					style="margin-right: 30px !important;">
					<md-icon md-src="/static/svg/login/iso.svg"></md-icon>
				</md-button>
				<md-button slot="buttons" class="md-just-icon md-simple md-white">
					<md-icon md-src="/static/svg/login/ccc.svg"></md-icon>
				</md-button>
				<div slot="inputs">
					<div v-if="showQrCode">
						<img class="QrCode" :src="qrCodeUrl" />
					</div>
					<md-field v-if="!showQrCode" class="md-form-group" style="margin-top: 33px;">
						<md-icon>face</md-icon>
						<label>账号</label>
						<md-input v-model="username" @keyup.enter="convert_password"></md-input>
					</md-field>
					<md-field v-if="!showQrCode" class="md-form-group" :md-toggle-password="false">
						<md-icon>lock_outline</md-icon>
						<label>密码</label>
						<md-input id="passwordInput" @keyup.enter="convert_login" v-model="password" type="password">
						</md-input>
					</md-field>
				</div>

				<div slot="footer" style="width: 100%;">
					<md-button v-if="!showQrCode" @click="doLogin" class="md-simple md-lg md-info " :style="loginBtn">
						登 录
					</md-button>
					<div style="width: 100%; display: flex;justify-content: flex-end;">
						<div @click="changShowQrCode" class="chang-btn" v-if="!showQrCode">
							扫码登录
						</div>
						<div @click="changShowQrCode" class="chang-btn" v-if="showQrCode">
							密码登录
						</div>
					</div>
				</div>


			</login-card>
		</div>
	<k-popup ref="loginRef" data-title="提示" data-append-to-body="true" data-width="400px">
		<h3>已登录,请关闭页面(3s后自动关闭)</h3>
	</k-popup>
	<k-popup ref="tipRef" data-title="提示" data-append-to-body="true" data-width="400px">
		<h3>请使用非Safari浏览器</h3>
		<a href="https://wiki.kkws.cn:8089/zh/%E4%BA%91%E6%A1%8C%E9%9D%A2/macos#h-5-safari%E7%99%BB%E5%BD%95%E6%B2%A1%E6%9C%89%E5%8F%8D%E5%BA%94">https://wiki.kkws.cn:8089/zh/%E4%BA%91%E6%A1%8C%E9%9D%A2/macos#h-5-safari%E7%99%BB%E5%BD%95%E6%B2%A1%E6%9C%89%E5%8F%8D%E5%BA%94</a>
	</k-popup>
	</div>
</template>
<script>
	import auth from "@/utils/auth.js"
	import LoginCard from "./LoginCard";
	import Tools from '@/utils/tools.js';
	import httpUtil from "./httpUtil";
	import KPopup from "../components/k-element/k-popup/k-popup";

	export default {
		components: {
			KPopup,
			LoginCard
		},
		props: {
			loginConfig: {
				type: Object,
				default: new Object()
			}
		},
		data() {
			return {
				remember: false, // 记住密码
				username: '',
				password: '',
				loading: false,
				showDialog: false,
				showQrCode: true,
				qrCodeUrl: "",
				loginStatus: false,
				code: "",
				diskThrough: httpUtil.diskThrough,
				task:undefined,
				dialogVisible:false
			};
		},
		beforeCreate() {
			if (typeof ipcRenderer != "undefined") {
				this.httpUtil.ajaxKKWS({
					type: "GET",
					url: "getToken",
				}).then(res => {
					if (res.code == "200" && res.msg) {
						let token=res.msg
						this.httpUtil.ajax({
							url: "graphql/checkToken.json",
							params: {
								Authorization: res.msg
							},
							errCallback: (data) => {

							}
						}).then(res => {
							if (res.success && res.returnmsg == "valid") {
								auth.setToken(token);
								this.loginStatus=true
								Tools.getLoginUser().then(res=>{
									this.$store.commit("setLoginUser", res)
									this.$router.push({
										path: "/main/out-disk",
										query:{
											reloadToken:true
										}
									})
								})

							}
						})
					}
				}).catch(err => {
					console.log("kkws服务未启动")
				})
			}

		},
		beforeDestroy() {
             if(undefined!=this.task){
             	clearTimeout(this.task)
			 }
		},
		created() {
			if((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))){
				this.$nextTick(()=>{
					this.$refs.tipRef.popup()
				})
				return
			}
			this.loadLoginCode();
			// if(typeof ipcRenderer != "undefined"){
			// 	this.keyDown()
			// }
			// if(typeof ipcRenderer == "undefined") {	// 网页环境
			// 	// 调用接口获取token
			// 	this.httpUtil.ajaxKKWS({
			// 		basePath: "http://localhost:18099/",
			// 		type: "GET",
			// 		url: "get/token",
			// 	}).then(res => {
			// 		if(res.msg !== '') {
			// 			// this.$refs.loginRef.popup()
			// 			// setTimeout(()=>{
			// 				window.close()
			// 			// },3000)
			// 		}
			// 	}).catch(err=>{
			// 		this.httpUtil.ajaxKKWS({
			// 			basePath: "http://127.0.0.1:18099/",
			// 			type: "GET",
			// 			url: "get/token",
			// 		}).then(res => {
			// 			if(res.msg !== '') {
			// 				// this.$refs.loginRef.popup()
			// 				// setTimeout(()=>{
			// 					window.close()
			// 				// },3000)
			// 			}
			// 		})
			// 	})
			// }
		},
		methods: {
			// 监听键盘
			keyDown() {
				document.onkeydown = (e) => {
					//事件对象兼容
					try {
						let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
						if (e1 && e1.keyCode == 82 && e1.ctrlKey) {
							this.ipcMainMethod("reload")
						} else if (e1 && e1.keyCode == 81 && e1.ctrlKey) {
							this.ipcMainMethod("app-exit")
						} else if (e1 && e1.keyCode == 77 && e1.ctrlKey) {
							this.ipcMainMethod("win-minimize")
						} else if (e1 && e1.keyCode == 73 && e1.ctrlKey) {
							this.ipcMainMethod("devtools")
						}
					}catch (e) {
						console.log(e)
					}
				}
			},
			ipcMainMethod(name) {
				ipcRenderer.send(name)
			},
			loadLoginCode() {
				this.httpUtil.query({
					url: "weiXin/getLoginCode.json"
				}).then(data => {
					var code = data.code;
					this.code = code;
					this.qrCodeUrl = this.httpUtil.basePath + "weiXin/getLoginWxacode.action?code=" + code;

					this.checkLoginCode();
				});
			},
			checkLoginCode() {
				if (!this.loginStatus) {
					this.httpUtil.query({
						url: "weiXin/loginByCode.json",
						params: {
							code: this.code
						},
						errCallback: () => {
								this.task=setTimeout(() => {
									this.checkLoginCode();
								}, 2000);
						}
					}).then(data => {
						this.loginStatus = true;
						auth.setToken(data.returndata.token);
						this.httpUtil.ajaxKKWS({
							basePath: "http://localhost:18099/",
							type: "GET",
							url: `push/token?token=${auth.getToken()}`,
						}).then(res=>{
                             if(res.msg=="success"){
								 // this.$refs.loginRef.popup()
								 // setTimeout(()=>{
									 window.close()
								 // },3000)
							 }else{
								 this.$message.warning("登录校验不通过,请刷新页面重新登录")
							 }
						}).catch(err=>{
							this.httpUtil.ajaxKKWS({
								basePath: "http://127.0.0.1:18099/",
								type: "GET",
								url: `push/token?token=${auth.getToken()}`,
							}).then(res=>{
								if(res.msg=="success"){
									// this.$refs.loginRef.popup()
									// setTimeout(()=>{
										window.close()
									// },3000)
								}else{
									this.$message.warning("登录校验不通过,请刷新页面重新登录")
								}
							})
						})
					});
				}
			},
			doLogin() {
				if (!this.username || !this.password) {
					Tools.alert("账号和密码不能为空", "danger");
					return;
				}
				this.httpUtil.query({
					url: "jwt/login.json",
					params: {
						username: this.username,
						password: this.password,
						flg: "1",
						odonate:true
					}
				}).then(data => {
					var code = data.returndata.code;
					if (code == "0000") {
						this.loginStatus = true;
						auth.setToken(data.returndata.token);
						this.httpUtil.ajaxKKWS({
							basePath: "http://localhost:18099/",
							type: "GET",
							url: `push/token?token=${auth.getToken()}`,
						}).then(res=>{
							if(res.msg=="success"){
								// this.$refs.loginRef.popup()
								// setTimeout(()=>{
									window.close()
								// },3000)
							}else{
								this.$message.warning("登录校验不通过,请刷新页面重新登录")
							}
						}).catch(err=>{
							this.httpUtil.ajaxKKWS({
								basePath: "http://127.0.0.1:18099/",
								type: "GET",
								url: `push/token?token=${auth.getToken()}`,
							}).then(res=>{
								if(res.msg=="success"){
									// this.$refs.loginRef.popup()
									// s1etTimeout(()=>{
										window.close()
									// },3000)
								}else{
									this.$message.warning("登录校验不通过,请刷新页面重新登录")
								}
							})
						})
					}else {
						Tools.alert("登录失败", "danger");
					}
				});
			},
			convert_password() {
				document.getElementById('passwordInput').focus()
			},
			convert_login() {
				this.doLogin()
			},
			changShowQrCode() {
				this.showQrCode = !this.showQrCode;
			}
		},
		computed: {
			loginBtn() {
				const ret = {};
				ret.color = this.loginConfig.loginColor + "!important";
				return ret;
			}
		}
	};
</script>

<style>
	.login-content i {
		width: 23px !important;
		height: 23px !important;
	}

	.chang-btn {
		color: #00bcd4;
		font-size: 13px;
		font-weight: bold;
		width: 80px;
		cursor: pointer;
	}

	.QrCode {
		width: 230px !important;
	}
</style>
