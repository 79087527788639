import kayak from '@/frame/kayak.js'
import httpUtil from '@/frame/httpUtil.js'
import moment from "moment";
import {
  MessageBox
} from "element-ui";
import global from "@/frame/global";

let Tools = {};


Tools.str2Json = function(str) {
  if (!str) {
    return null;
  }
  var json = null;
  json = eval("(" + str + ")");
  return json;
}

Tools.enterConvert = function(fieldval, replaceQuotation) {
  if (fieldval) {
    fieldval = fieldval.replace(/\n/g, "@#n#@");
    if (replaceQuotation === false) {
      fieldval = fieldval.replace(/\"/g, "##");
    }
  }
  return fieldval;
}

Tools.formatDate = function(date) {
  if (!date) {
    return "";
  }
  date = date + ''
  return date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8);
}

Tools.formatDateTime = function(date, time) {
  if (!date || !time) {
    return "";
  }
  date = date + ''
  time = time + ''

  let result = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8) + " ";
  if (time.length == 6) {
    return result + time.substring(0, 2) + ":" + time.substring(2, 4) + ":" + time.substring(4, 6);
  } else {
    return result + time.substring(0, 2) + ":" + time.substring(2, 4);
  }
}

Tools.diffDateTime = function(date1, time1, date2, time2) {
  let moment1 = moment(date1 + ' ' + time1);
  let moment2 = moment(date2 + ' ' + time2);

  let time = moment2.diff(moment1, 'second');
  let day = Math.floor(time / 86400);
  time = time % 86400;

  let hour = Math.floor(time / 3600);
  time = time % 3600;

  let minute = Math.floor(time / 60);
  time = time % 60;

  let second = time;

  return day + '天' + hour + '时' + minute + '分' + second + '秒';
}

Tools.formatTime = function(time) {
  if (!time) {
    return "";
  }
  time = time + ''
  return time.substring(0, 2) + ":" + time.substring(2, 4);
}

function formatMoney(s) {
  let dot = ','
  s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(2) + "";
  var l = s.split(".")[0].split("").reverse(),
    r = s.split(".")[1];
  t = "";
  for (i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? dot : "");
  }
  return t.split("").reverse().join("") + "." + r;
}

Tools.formatMoney = function formatMoney(s) {
  if (!s) {
    return ""
  }
  let dot = ','
  s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(2) + "";
  let l = s.split(".")[0].split("").reverse(),
    r = s.split(".")[1];
  let t = "";
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? dot : "");
  }
  return t.split("").reverse().join("") + "." + r;
}

Tools.apply = function(o, c) {
  if (o == null) {
    o = {};
  }
  if (o && c && typeof c == 'object') {
    for (var p in c) {
      o[p] = c[p];
    }
  }
  return o;
};


/**
 * 将数组转换成字符串
 */
Tools.array2str = function(arr, includeFunction) {
  if (arr == null) {
    return 'null';
  }
  var s = [],
    v, type;
  for (var i = 0; i < arr.length; i++) {
    v = arr[i], type = (typeof v);
    switch (type) {
      case 'function': //function对象不添加
        if (includeFunction === true) {
          v = '"' + String(v).replace(/[\n\r\t]/g, '').replace(/[\t]/g, ' ') + '"';
        } else {
          continue;
        }
        break;
      case 'string': //字符串添加双引号
        v = '"' + v + '"';
        break;
      case 'object':
        if (Tools.isArray(v)) {
          v = Tools.array2str(v, includeFunction);
        } else {
          v = Tools.json2str(v, includeFunction);
        }
        break;
    }
    s.push(v);
  }
  return '[' + s.join(',') + ']';
};

/**
 * 将JSON对象转换成字符串
 * 参数：includeFunction boolean类型，true则包含function值的属性也转成string，其他值则不转function属性，默认为false
 */
Tools.json2str = function(json, includeFunction, isConfOption) {
  if (json == null) {
    return 'null';
  }
  if (Tools.isArray(json)) {
    return Tools.array2str(json, includeFunction, isConfOption);
  }
  var s = [],
    k, v, type;
  for (k in json) {
    v = json[k], type = (typeof v);
    switch (type) {
      case 'function': //function对象不添加
        if (includeFunction === true) {
          //避免出现脚本错误，需要把一些特殊字符做替换
          v = String(v).replace(new RegExp('\/\/.*[\n\r]', 'g'), ' ') // 需要将单行注释给替换掉
            //换行和制表符
            .replace(new RegExp('[\n\r]', 'g'), '').replace(new RegExp('[\t]', 'g'), ' ');
          if (isConfOption === true) { //在confOption里
          } else {
            v = '"' + v + '"';
          }
        } else {
          continue;
        }
        break;
      case 'string': //字符串添加双引号
        var reg = new RegExp('"', "g"); //创建正则RegExp对象
        v = v.replace(reg, '\\"');
        v = '"' + v + '"';
        break;
      case 'object':
        if (Tools.isArray(v)) {
          v = Tools.array2str(v, includeFunction, isConfOption);
        } else {
          if (k == 'confOption') {
            v = '"' + Tools.json2str(v, includeFunction, true).replace(/["]/g, '\\"') + '"';
          } else {
            v = Tools.json2str(v, includeFunction, isConfOption);
          }
        }
        break;
    }
    s.push('"' + k + '":' + v);
  }
  return '{' + s.join(', ') + '}';
};

/**
 * 判断对象是否数组
 */
Tools.isArray = function(object) {
  if (object == null) {
    return false;
  }
  return object != null && typeof object == "object" &&
    object.splice != null && object.join != null && object.length != null;
};


Tools.downloadFileByParams = function(download_path, download_name, download_code) {
  $("#downloadForm").remove();
  var downloadFormHtml =
    '<form id="downloadForm" action="base/comn-download.action" method="post" class="k-hidden" target="_self">';
  downloadFormHtml += '<input type="text" name="download_path" value=' + download_path + ' />';
  downloadFormHtml += '<input type="text" name="download_name" value=' + download_name + ' />';
  downloadFormHtml += '<input type="text" name="download_code" value=' + download_code + ' />';
  downloadFormHtml += '</form>';

  var $downloadForm = $(downloadFormHtml);
  $downloadForm.appendTo($("body"));
  $downloadForm.submit();
}


Tools.download = function(path, filename) {
  httpUtil
    .download({
      url: "/base/comn-download.json",
      params: {
        "path": path
      }
    }, filename)
}

Tools.batch_download = function(paths, filename) {
  httpUtil
    .download({
      url: "/base/comn-batch-download.json",
      params: {
        "paths": paths
      }
    }, filename)
}

Tools.alert = function(message, type = global.notify_type.success,timeout=2500 ,icon = "add_alert", horizontalAlign = "center",
  verticalAlign = "top") {
  kayak.app.$notify({
    timeout: timeout,
    message: message,
    icon: icon,
    horizontalAlign: horizontalAlign,
    verticalAlign: verticalAlign,
    type: type
  });
}

Tools.confirm = function(callback, message, title, type, confirmButtonText, cancelButtonText, buttonClass) {
  let dataDescribe = message ? message : '确定执行该操作吗？'
  MessageBox.confirm(dataDescribe, title ? title : '操作提示', {
    confirmButtonText: confirmButtonText ? confirmButtonText : '确定',
    cancelButtonText: cancelButtonText ? cancelButtonText : '取消',
    cancelButtonClass: buttonClass ? buttonClass : 'el-button--info',
    type: type ? type : 'warning',
    beforeClose: (action, instance, done) => {
      if (action === 'confirm') {
        instance.confirmButtonLoading = true;
        instance.confirmButtonText = '执行中...';

        callback();
        done();
        instance.confirmButtonLoading = false;
      } else {
        done();
      }
    }
  }).catch(() => {});
}

Tools.getLoginUser = function() {
  return new Promise((resolve, reject) => {
    if (!Tools.loginUser) {
      httpUtil.ajax({
        url: 'getLoginUser.json',
        dataAfterSuccess: function(json) {
          Tools.loginUser = json.returndata;
          resolve(Tools.loginUser);
        }
      });
    } else {
      resolve(Tools.loginUser);
    }
  });
}

/**
 * 计算字符串字节长度(汉字两个字节)
 * @param str 目标字符串
 * @returns {number} 字节长度
 */
Tools.strByteLength = function(str) { //获取字符串的字节数，扩展string类型方法
  if (!str || str.length == 0) {
    return 0;
  }
  let b = 0;
  let l = str.length; //初始化字节数递加变量并获取字符串参数的字符个数
  for (let i = 0; i < l; i++) { //遍历字符串，枚举每个字符
    if (str.charCodeAt(i) > 255) { //字符编码大于255，说明是双字节字符
      b += 2; //则累加2个
    } else {
      b++; //否则递加一次
    }
  }
  return b; //返回字节数
}

Tools.uuid = function() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}

Tools.isVideos=function (fileName) {
  let hz = fileName.substring(fileName.lastIndexOf(".") + 1)
  let temp = "wmv、asf、asx、rm、 rmvb、mp4，3gp、mov、m4v、avi、dat、mkv、flv、vob"
  if (temp.indexOf(hz) > -1) {
    return true
  }
  return false
}
export default Tools;
