import Tools from '@/utils/tools.js'
import options from "vue-quill-editor/src/options";

export default {
  methods: {
    getGraphqlUrl(dataGraphql,dataDiffcondition){
      let data_graphql = dataGraphql.trim()
      let queryName = data_graphql.substring(data_graphql.indexOf("{")+1,data_graphql.indexOf("("))
      data_graphql += "treeConfig{"
      data_graphql += queryName
      data_graphql += ":{"
      data_graphql +="idcolumn: "+"id,"
      data_graphql +="textcolumn: "+"parent,"
      data_graphql += "diffcondition: '"+ dataDiffcondition + "'"
      data_graphql += "}"
      data_graphql += "}"
      return data_graphql
    },
    //处理数据
    handleData(rows) {
      let options=[]
      rows.map(row => {
        row[this.dataValueDisabled]=row['rowData'][this.dataValueDisabled]
        row[this.dataDisplayField]=row['rowData'][this.dataDisplayField]
        row[this.dataValueField]=row['rowData'][this.dataValueField]
        if(row.children){
           row.children=this.handleData(row.children)
          options.push(row)
        }else{
          options.push(row)
        }
      })
      return options
    },
    setRowDisabld(row) {
      if (row[this.dataValueDisabled] === true || row[this.dataValueDisabled] === 'true' || row[this.dataValueDisabled] === 'disabled' || (this.dataValueMethod !== undefined ? this.dataValueMethod(row) : false)) {
        row[this.props.disabled] = 'disabled'
      }
    },
    //异步加载
    loadActionData() {
      this.httpUtil.comnQuery({
        action: this.dataAction,
        params: this.params
      }).then(data => {
        this.options = this.handleData(data.rows);
      });
    },
    loadGraphqlData() {
      this.httpUtil.graphqlQurey({
        graphql: this.graphql,
        params: this.params
      }).then(data => {
        let graphqlFirst = this.graphql.substring(this.graphql.indexOf("{") + 1, this.graphql.indexOf("("));
        this.options = this.handleData(data[graphqlFirst].rows);
        console.log(this.options)
        if(this.dataAfterLoad){
          this.options=this.dataAfterLoad(this.options)
        }
      });
    },
    loadActionDataTree() {
      this.httpUtil.comnQueryTree({
        action: this.dataAction,
        params:this.params
      }).then(data => {
        this.options = this.handleData(data.rows);
      });
    },
    showNode (node,resolve) {
      const { data } = node
      if (data === undefined || data.length === 0 || node.level === 0) {
        return
      }
      const params = {}
      if (this.params.hasOwnProperty(this.dataParentField)) {
        params[this.dataParentField] = data[this.props.value]
      }
      setTimeout(() => {
        if (this.dataAction) {
          this.httpUtil.comnQuery({
            action: this.dataAction,
            params: params
          }).then(data => {
            resolve(this.handleData(data.rows))
          })
        } else if (this.dataGraphql) {
          this.httpUtil.graphqlQurey({
            graphql: this.dataGraphql,
            params: params
          }).then(data => {
            // 获取请求头
            const graphqlFirst = this.dataGraphql.substring(this.dataGraphql.indexOf('{') + 1, this.dataGraphql.indexOf('('))
            resolve(this.handleData(data[graphqlFirst].rows))
          })
        }
      }, 1000)
    }
  }
}
