import Tools from "@/utils/tools";

const TokenKey = 'Authorization'

let auth = {};

auth.getToken = function() {
  return localStorage.getItem(TokenKey)
};

auth.setToken = function(token) {
  return localStorage.setItem(TokenKey, token)
};

auth.removeToken = function() {
  Tools.loginUser = undefined
  return localStorage.removeItem(TokenKey)
};

auth.check = function(server) {
  let userid = localStorage.getItem("userid");

  if (userid == 'admin') { //超级管理员不控制权限
    return true;
  }

  let servers = localStorage.getItem("servers");
  return servers.indexOf(server) != -1;
}

export default auth;
