/**
 * 全局常量、方法封装模块
 * 通过原型挂载到Vue属性
 * 通过 this.global 调用
 */

let global = {};

global.notify_type = {
  success: "success",
  warning: "warning",
  info: "info",
  error: "error"
}

global.wf_process_default_type = "2";

global.wf_process_status = {
  running: "1",
  reject: "2",
  refuse: "3",
  reject_to_apply: "4",
  pass: "5",
  re_apply: "6",
  approvaling: "7",
  revoke: "8",
  finish: "9"
}

global.wf_business_status = {
  ready: "0",
  processing: "1",
  finish: "2",
  error: "3",
  error_confirmed: "4"
}

global.wf_application_en_task_name = "applicationTask"



export default global;
