<template>
    <div>
        <k-popup ref="tipRef" data-title="提示" data-append-to-body="true" data-width="400px">
            <h3>请使用非Safari浏览器或窗口登录</h3>
<!--            <a href="https://wiki.kkws.cn:8089/zh/%E4%BA%91%E6%A1%8C%E9%9D%A2/macos#h-5-safari%E7%99%BB%E5%BD%95%E6%B2%A1%E6%9C%89%E5%8F%8D%E5%BA%94">https://wiki.kkws.cn:8089/zh/%E4%BA%91%E6%A1%8C%E9%9D%A2/macos#h-5-safari%E7%99%BB%E5%BD%95%E6%B2%A1%E6%9C%89%E5%8F%8D%E5%BA%94</a>-->
        </k-popup>
    </div>
</template>

<script>
    export default {
        name: "LoginTransfer.vue",
        data(){
            return{

            }
        },
        created() {
            if((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))){
                this.$nextTick(()=>{
                    this.$refs.tipRef.popup()
                })
                return
            }
            this.httpUtil.ajax({
               url:"getKeyclockConfig",
               params:{
                   type:"odonate"
               }
            }).then(res=>{
                window.location.href=res.returndata.url
            })
        },
        methods:{

        }
    }
</script>

<style scoped>

</style>