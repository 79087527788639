<template>
  <div>
    <k-popup ref="kGridDisplayPopup" data-title="详情">
      <k-form ref="displayForm" :data-col="2" dataTotalWidth="780px">
        <k-form-item v-for="(value,key,index) in gridColumnOptions" :key="index" :label="value.dataHeader + '：'">
          <k-field-display v-model="row[value.dataName]" :data-dict="value.dataDict" :data-type="value.dataType" :data-clearable="false"/>
        </k-form-item>
      </k-form>
    </k-popup>
  </div>
</template>



<script>

  import props from "@/components/k-element/common/k-field-props.js";
  import event from "@/components/k-element/common/k-field-event.js";
  import emitter from "@/components/k-element/common/k-emitter.js";

export default {
  name: 'KGridDisplay',
  mixins: [props(), event(), emitter()],
  props: {
    row: {
      type: Object
    },
    gridColumnOptions: {
      type: Array
    }
  },
  methods: {
    popup() {
      this.$refs.kGridDisplayPopup.popup()
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/ .md-field:after, .md-field:before {
    bottom: 10px;
    border: 1px solid white;
  }
</style>

