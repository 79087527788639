import { render, staticRenderFns } from "./k-form.vue?vue&type=template&id=304d22c7&"
import script from "./k-form.vue?vue&type=script&lang=js&"
export * from "./k-form.vue?vue&type=script&lang=js&"
import style0 from "./k-form.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports