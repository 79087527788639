<template>
	<el-tooltip v-if="dataDescript && isShow" class="item" effect="dark" :content="dataDescript" placement="bottom">
		<md-button @click="handle($event)" :disabled="loading || dataDisabled === true || dataDisabled === 'true'"
			:class="['md-sm', disabledGrey ? 'md-disabled' : '']">
			<i v-show="loading" class="el-icon-loading"></i>
			<slot>
			</slot>
		</md-button>
	</el-tooltip>
	<md-button v-else-if="isShow" @click="handle($event)"
		:disabled="loading || dataDisabled === true || dataDisabled === 'true'"
		:class="[disabledGrey ? 'md-disabled' : '']">
		<i v-show="loading" class="el-icon-loading"></i>
		<slot>
		</slot>
	</md-button>
</template>

<script>
	import emitter from "@/components/k-element/common/k-emitter.js";

	import Tools from "@/utils/tools.js"
	import auth from "@/utils/auth.js"

	export default {
		name: 'KBtn',
		mixins: [emitter()],
		props: {
			dataTarget: {
				type: String
			},
			dataParams: {

			},
			dataDisabled: {
				type: [Boolean, String],
				default: false
			},
			dataDisabledGrey: {
				type: Boolean,
				default: true
			},
			dataFunctype: {
				type: String
			},
			dataAction: {
				type: String
			},
			dataGraphql: {
				type: String
			},
			dataUrl: {
				type: String
			},
			dataAfterSuccess: {
				type: Function
			},
			dataHandler: {
				type: Function
			},
			dataConfirm: {
				type: [Boolean, String],
				default: false
			},
			dataFrom: {
				type: String
			},
			dataModel: {},
			dataDescript: {
				type: String
			},
			dataValidateForm: {
				type: Boolean,
				default: true
			},
			dataExportName: {
				type: String,
				default: undefined
			},
			dataClosePopup:{
				type: Boolean,
				default: true
			},
		},
		data() {
			return {
				form: null, //表单对象
				loading: false,
				oldData: '',
				isShow: true,
				mdSVGLoader: null
			}
		},
		computed: {
			disabledGrey: function() {
				return this.dataDisabledGrey && (this.dataDisabled === true || this.dataDisabled === 'true');
			},
			justIcon: function() {
				return this.$el.className.indexOf("md-simple") > -1 && this.$el.className.indexOf("md-just-icon") > -1;
			}
		},
		created() {
			this.$on('k.form.addForm', (form) => {
				if (form) {
					this.form = form;
				}
			});
			if (this.dataModel) {
				this.oldData = Tools.json2str(this.dataModel);
			}
		},
		mounted() {
			if (this.disabledGrey) {
				let mdSVGLoader = this.getChildrens("MdSVGLoader", this.$children);
				if (mdSVGLoader && mdSVGLoader.length === 1) {
					this.mdSVGLoader = mdSVGLoader[0];

					this.$watch('mdSVGLoader.html', function(newVal, oldVal) {
						if (newVal && newVal != oldVal) {
							let fill;
							if (this.justIcon) {
								// 置灰
								fill = "fill=\"#6c6868\"";
							} else {
								// 转为白色
								fill = "fill=\"#FFFFFF\"";
							}
							this.mdSVGLoader.html = newVal.replace(/fill=[\"\'].*[\"\']/, fill)
						}
					})
				}
			}
		},
		methods: {
			handle($event) {
				var children = $event.currentTarget.childNodes[0].childNodes[0].childNodes
				var child2 = []
				for (var i = 0; i < children.length; i++) {
					if (children[i].nodeName == 'I') {
						child2.push(children[i])
					}
				}
				let params = {};
				if (this.dataParams) {
					if(typeof this.dataParams=="object"){
						params = Object.assign(params, this.dataParams);
					}else{
						params = Object.assign(params, Tools.str2Json(this.dataParams));
					}
				}

				//如果默认表单对象不存在，尝试获取指定表单
				if (!this.form) {
					if (this.dataFrom) {
						this.form = this.getParentRef(this.dataFrom);
					}
				}

				if (this.dataModel) { //如果定义data-model，则获取引用对象
					params = Object.assign(params, this.dataModel);
				}

				// 从表格行获取对象
				let kGrid = this.getParent("KGrid");
				if (kGrid) {
					let formerData = kGrid.getRowData($event);
					this.oldData = Tools.json2str(formerData);
					params = Object.assign(params, formerData);
				}

				//执行data-hanlder事件
				if (this.dataHandler) {
					let re = this.dataHandler(params);

					if (re === false) { //不做任何处理
						return;
					}
				}

				if (this.dataFunctype == "RESET") { //重置表单
					if (this.form) {
						this.form.reset();
					}
				} else if (this.dataFunctype == "PAGE") { //打开新页面
					if (!this.dataTarget) {
						console.error("为定义data-target属性");
						return;
					}
					this.$router.push({
						path: this.dataTarget,
						query: params
					});
				} else if (this.dataFunctype == "EXPORT") { //导出表格
					if (this.dataTarget) {
						let target = this.getParentRef(this.dataTarget);
						//判断dataTarget是不是表格，如果是表格，则刷新表格
						if (target && target.$options.name == "KGrid") {
							let queryParams = target.buildQueryParams();
							if (queryParams.hasOwnProperty("start")) {
								delete queryParams["start"]
								delete queryParams["limit"]
							}
							let headers = "";
							let gridColumnOptions = target.gridColumnOptions;
							let unToDict = []
							gridColumnOptions.forEach(o => {
								if (!o.dataHeader || !o.dataName) {
									return;
								}
								headers = headers + o.dataHeader + ":";
								headers = headers + o.dataName + ":";
								headers = headers + (o.dataType ? o.dataType : "") + ":";
								headers = headers + (o.dataDict ? o.dataDict : "") + ":";
								headers = headers + ",";
								if (o["dataExportDict"] == "false" || o["dataExportDict"] == false) {
									unToDict.push(o["dataName"])
								}
							})
							let graphql = target.dataGraphql;
							if (queryParams) {
								let graphqlFirst = graphql.substring(0, graphql.indexOf(")"));
								let graphqlSecond = graphql.substring(graphql.indexOf(")"));
								for (var key in queryParams) {
									if (!queryParams[key] && queryParams[key] !== 0) {
										continue;
									}
									if (typeof queryParams[key] == "string") {
										graphqlFirst += "," + key + ": \"" + Tools.enterConvert(queryParams[key], undefined) +
												"\"";
									} else {
										graphqlFirst += "," + key + ":" + queryParams[key];
									}
								}
								graphql = graphqlFirst + graphqlSecond;
							}
							let params = {
								"headers": headers,
								"graphql": graphql,
								"unToDict": unToDict.join(",")
							};
							this.httpUtil
									.download({
										url: "excel/download.json",
										params: params
									}, this.dataExportName)
						} else {
							console.error("表格导出需配置操作的表格引用")
						}
					}
				} else if (this.dataFunctype == "DOWNLOAD") { //下载文件
					this.loading = true;
					child2[1].setAttribute("style", "display:none;")
					this.httpUtil.download({
						url: this.dataUrl,
						params: params,
						callback: () => {
							this.loading = false;
							child2[1].setAttribute("style", "display:inline;")
						}
					});
				} else if (this.dataFunctype == "POPUP") { //弹窗
					setTimeout(() => {
						let target = this.getParentRef(this.dataTarget);
						//判断dataTarget是不是弹窗，如果是弹窗，则关闭弹窗
						if (target && target.$options.name == "KPopup") {
							target.popup();
						}
					}, 50)
				} else if (this.dataFunctype == "CLOSE") { //关闭弹窗
					let kPopup = this.getParent("KPopup");
					if (kPopup) {
						kPopup.close();
					}
				} else if (this.dataFunctype == "SUBMIT") { //提交

					//校验表单
					if ((this.dataValidateForm == true || this.dataValidateForm == 'true') && this.form) {
						let re = this.form.validate();
						if (re === false) {
							return;
						}
					}
					if (this.dataConfirm === 'true' || this.dataConfirm === true) {
						let _dataDescript = this.dataDescript ? this.dataDescript : "执行该操作";
						this.$confirm("确认" + _dataDescript + "吗？", '操作提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							cancelButtonClass: 'el-button--info',
							type: 'warning',
							beforeClose: (action, instance, done) => {
								if (action === 'confirm') {
									instance.confirmButtonLoading = true;
									instance.confirmButtonText = '执行中...';
									this.submitHandler(params, data => {
										if (this.dataTarget) {
											let target = this.getParentRef(this.dataTarget);
											//判断dataTarget是不是表格，如果是表格，则刷新表格
											if (target && target.$options.name == "KGrid") {
												target.load(target.getCachedParams());
											}
										}

										let kPopup = this.getParent("KPopup");
										if (kPopup&&this.dataClosePopup) {
											kPopup.close();
										}
										instance.confirmButtonLoading = false;
										done();
									}, () => {
										done();
										instance.confirmButtonLoading = false;
									});
								} else {
									done();
								}
							}
						}).catch(() => {});
					} else {
						this.loading = true;
						child2[1].setAttribute("style", "display:none;")
						this.submitHandler(params, () => {
							this.loading = false;
							if (this.dataTarget) {
								let target = this.getParentRef(this.dataTarget);
								//判断dataTarget是不是表格，如果是表格，则刷新表格
								if (target && target.$options.name == "KGrid") {
									target._load(target.getCachedParams());
								}
							}

							let kPopup = this.getParent("KPopup");
							if (kPopup&&this.dataClosePopup) {
								kPopup.close();
							}
						}, () => {
							this.loading = false;
							child2[1].setAttribute("style", "display:inline;")
						});
					}
				}
			},
			//按钮提交操作
			submitHandler(params, afterSuccess, callback) {
				if (this.dataAction) { //通过Action提交
					this.submitAction(params, afterSuccess, callback);
				} else if (this.dataGraphql) { //通过Graphql提交
					this.submitGraphql(params, afterSuccess, callback);
				} else if (this.dataUrl) { //通过url提交
					this.submitUrl(params, afterSuccess, callback);
				} else {
					afterSuccess();
				}
			},
			submitAction(params, afterSuccess, callback) {
				this.httpUtil.comnUpdate({
					action: this.dataAction,
					params: params,
					mask: false,
					callback: callback,
					dataAfterSuccess: this.dataAfterSuccess
				}).then(data => {
					if (afterSuccess) {
						afterSuccess(data);
					}
				});
			},
			submitGraphql(params, afterSuccess, callback) {
				this.httpUtil.graphqlUpdate({
					graphql: this.dataGraphql,
					params: params,
					mask: false,
					callback: callback,
					dataAfterSuccess: this.dataAfterSuccess
				}).then(data => {
					if (afterSuccess) {
						afterSuccess(data);
					}
				});
			},
			submitUrl(params, afterSuccess, callback) {
				this.httpUtil.update({
					url: this.dataUrl,
					params: params,
					mask: false,
					callback: callback,
					dataAfterSuccess: this.dataAfterSuccess
				}).then(data => {
					if (afterSuccess) {
						afterSuccess(data);
					}
				});
			}
		}
	};
</script>
