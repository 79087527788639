<template>

</template>

<script>
    import KBtn from "../components/k-element/k-btn/k-btn";
    export default {
        name: "Sso.vue",
        components: {KBtn},
        data(){
            return{

            }
        },
        mounted() {
           let token=this.$route.query.authorization
            this.httpUtil.ajaxKKWS({
                basePath: "http://localhost:18099/",
                type: "GET",
                url: 'push/token?token='+token,
            }).then(res=>{
                if(res.msg=="success"){
                    // if(navigator.userAgent.indexOf('Edge') !== -1){
                    //     window.location.href="about:blank";
                    //     window.close();
                    //     return
                    // }
                    if(navigator.userAgent.indexOf('Firefox') !== -1){
                        this.$alert('请手动关闭浏览器的标签栏', '登录成功', {
                            confirmButtonText: '确定',
                            callback: action => {
                            }
                        });
                        return
                    }
                    try {
                        // window.opener = null
                        // window.open('', '_self')
                        // window.close()
                        // window.open("about:blank","_self").close()
                        // this.$router.push({
                        //     path:"/close"
                        // })
                        // this.httpUtil.a=111
                    }catch (e) {
                    }
                    this.$alert('请手动关闭浏览器的标签栏', '登录成功', {
                        confirmButtonText: '确定',
                        callback: action => {
                        }
                    });
                }else{
                    this.$message.warning("登录校验不通过,请刷新页面重新登录")
                }
            }).catch(err=>{
                this.httpUtil.ajaxKKWS({
                    basePath: "http://127.0.0.1:18099/",
                    type: "GET",
                    url: 'push/token?token='+token,
                }).then(res=>{
                    if(res.msg=="success"){
                        // // if(navigator.userAgent.indexOf('Edge') !== -1){
                        // //     window.location.href="about:blank";
                        // //     window.close();
                        // //     return
                        // // }
                        // if(navigator.userAgent.indexOf('Firefox') !== -1){
                        //     this.$alert('请手动关闭浏览器的标签栏', '登录成功', {
                        //         confirmButtonText: '确定',
                        //         callback: action => {
                        //         }
                        //     });
                        //     return
                        // }
                        // try {
                        //     window.opener = null
                        //     window.open('', '_self').close()
                        // }catch (e) {
                        //
                        // }
                        // window.location.href="about:blank";
                        // window.close();
                        // this.$alert('请手动关闭浏览器的标签栏', '登录成功', {
                        //     confirmButtonText: '确定',
                        //     callback: action => {
                        //     }
                        // });
                    }else{
                        this.$message.warning("登录校验不通过,请刷新页面重新登录")
                    }
                }).catch(err2=>{
                    this.$message.warning("请求本地kkws服务失败,请有odonate的窗口登录")
                })
            })
        },
        methods:{

        },
    }
</script>

<style scoped>

</style>