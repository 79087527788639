<template>
  <div>
    <h4>表单信息</h4>
    <k-form v-if="!taskFormComponentName">
      <k-form-item v-for="(item,index) in businessInfo" :key="index" :label="item.label">
        <k-field-display v-model="item.value" :data-dict="item.dict"/>
      </k-form-item>
    </k-form>
    <component v-if="taskFormComponentName" :is="taskFormComponentName"
               :task-info="taskInfo" :submit-data="submitData"/>
  </div>
</template>

<script>
  export default {
    name: "KFlowFormInfo",
    props: {
      taskInfo: {}
    },
    data() {
      return {
        taskFormComponentName: null,
        businessInfo: [],
        submitData: {}
      };
    },
    methods: {
      initFormLabelInfo() {
        const latestData = this.submitData;
        this.httpUtil
          .ajax({
            url: "/wf/processInstance/label/info.json",
            params: {processInstanceId: this.taskInfo.processInstanceId}
          })
          .then(res => {
            let labelInfoData = res.data;
            let businessInfo = [];
            for (let field in latestData) {
              let info = labelInfoData[field];
              if (!info || !info.label) {
                continue;
              }
              info.value = latestData[field];
              businessInfo.push(info);
            }

            this.businessInfo = businessInfo;
          });
      }
    },
    created() {
      this.httpUtil
        .ajax({
          url: "/wf/conf/getFormConf.json",
          params: {processId: this.taskInfo.processId}
        })
        .then(res => {
          let formConfData = res.data;
          if (formConfData && formConfData.length > 0) {
            let initFormLabelInfo = true;

            // 默认取申请节点的表单组件，可以被覆盖
            this.taskFormComponentName = formConfData[0].formUrl;

            if (this.taskFormComponentName && this.taskFormComponentName.length > 0) {
              initFormLabelInfo = false;
            }

            for (let i = 1; i < formConfData.length; i++) {
              if (formConfData[i].taskName == this.taskInfo.taskName && formConfData[i].formUrl) {
                this.taskFormComponentName = formConfData[i].formUrl;
                initFormLabelInfo = false;
                break;
              }
            }

            this.httpUtil
              .ajax({
                url: "/wf/processInstance/data/latest.json",
                params: {processInstanceId: this.taskInfo.processInstanceId}
              })
              .then(res => {
                this.submitData = res.data;
                if (initFormLabelInfo) {
                  this.initFormLabelInfo();
                }
              });
          }
        });


    }
  };
</script>
