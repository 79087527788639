<template>

</template>

<script>
    import KBtn from "../components/k-element/k-btn/k-btn";
    export default {
        name: "Close",
        components: {KBtn},
        mounted() {
             window.close()
        },
        methods:{
            close(){
                window.close()
            }
        }
    }
</script>

<style scoped>

</style>