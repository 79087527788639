import NotFound from '@/frame/404.vue'
import Login from '@/frame/Login.vue'
import LoginTransfer from '@/frame/LoginTransfer.vue'
import Sso from '@/frame/Sso.vue'
import Close from '@/frame/Close.vue'
import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/login',
            component: Login,
            name: '',
            hidden: true
        },
        {
            path: '/loginTransfer',
            component: LoginTransfer,
            name: '',
            hidden: true
        },
        {
            path: '/sso',
            component: Sso,
            name: '',
            hidden: true
        }, {
            path: '/close',
            component: Close,
            name: '',
            hidden: true
        },
        {
            path: '/404',
            component: NotFound,
            name: '',
            hidden: true
        }, {
            path: '/',
            hidden: true,
            redirect: {
                path: '/loginTransfer'
            }
        }, {
            path: '*',
            hidden: true,
            redirect: {
                path: '/404'
            }
        },
    ]
})
