<template>
  <md-card class="md-card-login" :class="{ 'md-card-hidden': cardHidden }">
    <md-card-header class="md-card-header-" :style="loginChard">
      <slot name="title"></slot>
      <div class="social-line">
        <slot name="buttons"></slot>
      </div>
    </md-card-header>

    <md-card-content>
      <slot name="description"></slot>
      <slot name="inputs"></slot>
    </md-card-content>

    <md-card-actions>
			
      <slot name="footer"></slot>
			
    </md-card-actions>
  </md-card>
</template>

<script>
  export default {
    name: "login-card",
    data() {
      return {
        cardHidden: true
      };
    },
    beforeMount() {
      setTimeout(this.showCard, 400);
    },
    methods: {
      showCard: function() {
        this.cardHidden = false;
      },
    },
    computed: {
      loginChard() {
        const ret = {};
        ret.background = "#00BCD4";
        return ret;
      }
    }
  };
</script>

<style lang="css">
  .social-line {
    height: 91px;
    top: 50%;
    padding-top: 33px;
  }

  .md-just-icon .md-ripple {
    width: 23px !important;
    height: 23px !important;
  }
</style>
