<template>
  <div>
    <k-form ref="approvalForm">
      <k-form-item label="审批意见">
        <k-field-text v-model="formData.opinion"></k-field-text>
      </k-form-item>
      <k-form-item v-for="(item, index) in formFieldConfs" :key="index" :label="item.displayName">
        <component
          :is="item.fieldType"
          v-model="item.value"
          :data-allowblank="false"
          :data-dict="item['data-dict']"
        ></component>
      </k-form-item>
      <k-form-footer></k-form-footer>
    </k-form>
    <k-form ref="rejectForm" :data-col="1">
      <k-form-item label="驳回节点" v-if="btns.indexOf('2')>-1">
        <k-field-select
          data-url="/wf/conf/queryRejectTaskSelect.json"
          v-model="formData.taskName"
          :data-allowblank="false"
          :data-params='{"processId": taskInfo.processId, "taskName": this.taskInfo.taskName}'
          data-display-field="text"
          data-value-field="id"
        ></k-field-select>
      </k-form-item>
      <k-form-footer></k-form-footer>
    </k-form>
    <k-btn
      class="md-primary"
      data-functype="SUBMIT"
      v-if="btns.indexOf('3')>-1"
      data-url="/wf/approval/doApproval.json"
      :data-handler="passDataHandler"
      data-target="taskGrid"
      :data-model="formData"
      data-from="approvalForm"
      data-params='{"result":"5","isSurrogate": "true"}'
    >
      <i class="icon-confirm" />通过
    </k-btn>
    <k-btn
      class="md-danger"
      data-functype="SUBMIT"
      v-if="btns.indexOf('1')>-1"
      data-url="/wf/approval/doApproval.json"
      :data-validate-form="false"
      data-target="taskGrid"
      :data-model="formData"
      data-from="approvalForm"
      data-params='{"result":"3","isSurrogate": "true"}'
    >
      <i class="icon-cancel" />拒绝
    </k-btn>
    <k-btn
      class="md-warning"
      data-functype="SUBMIT"
      v-if="btns.indexOf('2')>-1"
      data-url="/wf/approval/doApproval.json"
      data-target="taskGrid"
      :data-model="formData"
      data-from="rejectForm"
      data-params='{"result":"2","isSurrogate": "true"}'
    >
      <md-icon md-src="/static/svg/block_white.svg" />驳回
    </k-btn>
  </div>
</template>

<script>
  import KGrid from "@/components/k-element/k-grid/k-grid";
  import KBtn from "@/components/k-element/k-btn/k-btn";
  import Tools from "@/utils/tools.js";
  import KForm from "@/components/k-element/k-from/k-form";
  import KFormItem from "@/components/k-element/k-from/k-form-item";
  import KFieldText from "@/components/k-element/k-field-text/k-field-text";
  import KFormFooter from "@/components/k-element/k-from/k-form-footer";
  import KFieldSelect from "@/components/k-element/k-field-select/k-field-select";

  export default {
    name: 'KFlowForm',
    components: {KFieldSelect, KFormFooter, KFieldText, KFormItem, KForm, KBtn, KGrid},
    props: {
      taskInfo: {},
    },
    data() {
      return {
        formData: {},
        btns: '',
        enableAttachment: false,
        attachmentIds: '',
        taskFormComponentName: null,
        formFieldConfs: [],
        resultDict: {
          "1": "开始",
          "2": "驳回",
          "3": "拒绝",
          "4": "驳回到开始申请节点",
          "5": "通过",
          "6": "重新提交申请",
          "7": "审批中",
          "9": "完成"
        }
      };
    },
    created() {
      this.httpUtil
        .ajax({
          url: "/wf/wf/attachment/enableAttachment.json",
          params: {"processId": this.taskInfo.processId}
        })
        .then(res => {
          this.enableAttachment = res.data == 1 ? true : false;
        });

      this.httpUtil
        .ajax({
          url: "/wf/conf/getTaskBtns.json",
          params: {"processId": this.taskInfo.processId}
        })
        .then(res => {
          if (res && res.data && res.data.length > 0) {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
              if (this.taskInfo.taskName == data[i].taskName) {
                let btns = data[i].btns;
                if (btns) {
                  this.btns = btns;
                }
                break;
              }
            }
          }
        });

      this.httpUtil
        .ajax({
          url: "/wf/conf/queryOptionFormConfig.json",
          params: {"processId": this.taskInfo.processId, "taskName": this.taskInfo.taskName}
        })
        .then(res => {
          let data = res.data;
          if (data) {
            data = JSON.parse(data);
            if (data.fieldsConf && data.fieldsConf.length > 0) {
              this.formFieldConfs = data.fieldsConf
            }
          }
        });

      if (this.taskInfo) {
        this.formData.taskId = this.taskInfo.taskId
      }
    },
    methods: {
      passDataHandler(value) {
        if (this.formFieldConfs && this.formFieldConfs.length > 0) {
          let extraData = []
          this.formFieldConfs.forEach(o => {
            let data = {}
            data.name = o.name;
            data.value = o.value;
            data.displayName = o.displayName;
            extraData.push(data)
          })

          value.extraData = JSON.stringify(extraData);
        }
        if (this.enableAttachment) {
          value.attachmentIds = this.attachmentIds;
        }
      }
    },
    mounted(){
      this.bus.$on('change',(msg) => {
        this.attachmentIds = msg
      })
    }
  }
</script>
