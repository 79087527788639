<template>
  <md-card class="box-card" style="overflow: visible;">
    <md-card-header class="md-card-header-text md-card-header-green" style="margin-right: 0;">
      <div class="search-header">
        <div v-if="dataCard" class="card-icon" :style="iconStyle">
          <md-icon md-src="/static/svg/form.svg"></md-icon>
        </div>
        <div>
          <i class="el-icon-d-caret" @click="show"></i>
        </div>
      </div>
    </md-card-header>

    <div slot="header" class="clearfix" style="text-align:right">

    </div>
    <div class="show-form" id="show-form">
      <k-form ref="searchForm" :data-col="0">
        <slot></slot>
      </k-form>
    </div>

    <div class="k-form-search-footer">
      <k-btn class="md-success" :data-handler="query">
        <md-icon md-src="/static/svg/search.svg"></md-icon>查询
      </k-btn>
      <k-btn class="md-info" data-functype="RESET" data-type="warning" data-from="searchForm">
        <md-icon md-src="/static/svg/reset.svg"></md-icon>重置
      </k-btn>
      <slot  name="button"></slot>
    </div>

  </md-card>
</template>

<script>
  import KBtn from "../k-btn/k-btn";
  import emitter from "@/components/k-element/common/k-emitter.js";
  import props from "@/components/k-element/common/k-field-props.js";

  export default {
    name: 'kFormSearchCustomize',
    mixins: [props(), emitter()],
    components: {
      KBtn
    },
    props: {
      dataCard:{
        type:Boolean,
        default:true
      },
      dataTarget: {
        type: String
      }
    },
    data() {
      return {
        extends: true,
      }
    },
    computed: {
      iconStyle() {
        let iconStyle = {};
        iconStyle.background = "#4caf50"
        return iconStyle;
      }
    },
    methods: {
      query() {
        this.$emit("click")
        if (!this.dataTarget) {
          console.error("k-from-search需要指定data-target.")
        }
        let target = this.getParentRef(this.dataTarget);
        if (target && target.$options.name == "KGrid") {
          let params = {};

          for (let field in this.value) {
            let valueElement = this.value[field];
            if (valueElement) {
              params[field] = valueElement;
            }
          }

          let re = this.$refs.searchForm.validate();
          if (re === false) {
            return;
          }

          target.load(params)
        } else {
          console.error("data-target不存在或data-target不是KGrid组件.")
        }

      },
      show() {
        let e = document.getElementById('show-form')
        if (this.extends) {
          e.style.display = "none"
        } else {
          e.style.display = ""
        }
        this.extends = !this.extends
      },

    }

  }
</script>

<style lang="scss">
  @import './k-form-search.scss';
</style>
