<template>
  <div class="sidebar" :data-color="activeColor" :data-image="backgroundImage" :data-background-color="backgroundColor"
    :style="sidebarStyle">
    <div class="logo">

<!--      <div class="logo-img">-->
<!--        <div>-->
<!--          <img src="./../../../assets/img/white-logo.png" alt=""></img>-->
<!--        </div>-->
<!--        <div v-show="!showShortName">-->
<!--          <span>Kayak wise</span>-->
<!--          <span>开科唯识</span>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="logo-name" :class="{'mini-logo-name':showShortName}">-->
<!--        <span class="logo-name1" v-show="!showShortName">资金管理平台</span>-->
<!--        <span class="logo-name2" :class="{'logo-name3':showShortName}"  v-text="getLogoName()"></span>-->
<!--      </div>-->

      <div class="navbar-minimize" :class="{'mini-navbar-minimize':showShortName}">
        <md-button id="minimizeSidebar" class="md-round md-just-icon md-transparent" @click="minimizeSidebar">
          <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
        </md-button>
      </div>
    </div>

    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <md-list :class="{'mini-md-list':showShortName}" class="nav">
        <slot name="links">
<!--          <sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">-->
<!--            <sidebar-item v-for="(subLink, index) in link.children" :key="subLink.name + index" :link="subLink">-->
<!--            </sidebar-item>-->
<!--          </sidebar-item>-->
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
  export default {
    name: "sidebar",
    props: {
      showShortName:{
        type:Boolean,
        default:false,
      },
      title: {
        type: String
      },
      activeColor: {
        type: String,
        default: "green",
        validator: value => {
          let acceptedValues = [
            "",
            "purple",
            "azure",
            "green",
            "orange",
            "danger",
            "rose"
          ];
          return acceptedValues.indexOf(value) !== -1;
        }
      },
      backgroundImage: {
        type: String,
        default: "./img/sidebar-2.jpg"
      },
      backgroundColor: {
        type: String,
        default: "black",
        validator: value => {
          let acceptedValues = ["", "black", "white", "red"];
          return acceptedValues.indexOf(value) !== -1;
        }
      },
      logo: {
        type: String,
        default: "./../assets/img/menuLogo.png"
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide() {
      return {
        autoClose: this.autoClose
      };
    },
    methods: {
      minimizeSidebar() {
        if (this.$sidebar) {
          this.$sidebar.toggleMinimize();
        }
      },
      getLogoName(){
        if(this.showShortName){
          return "资金"
        }else{
          return "资金业务管理系统"
        }
      }
    },
    computed: {
      sidebarStyle() {
        return {
          backgroundImage: `url(${this.backgroundImage})`
        };
      }
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false;
      }
    }
  };
</script>
<style lang="scss" scoped>
  @media (min-width: 992px) {

    .navbar-search-form-mobile,
    .nav-mobile-menu {
      display: none;
    }
  }

  /*.sidebar-wrapper{*/
  /*  width: 205px;*/
  /*}*/
  .navbar-minimize{
    right: 5px;
    top:10px;
  }
  .mini-navbar-minimize{
    right: 25px;
    top:10px;
    opacity: 1;
  }
  .logo:after{
     height: 0px;
  }
  .logo{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 25px;
    .logo-img{
      width: 150px;
      display: flex;
      justify-content: center;
      div:nth-child(2){
         margin-left: 15px;
         display: flex;
         flex-direction: column;
         color: white;
         span:nth-child(1){
           font-style:italic;
         }
      }
    }
    .mini-logo-name{
      margin-top: 50px;
    }
    .logo-name{
      width: 150px;
      color: white;
      display: flex;
      flex-direction: column;
      .logo-name1{
         font-size: 14px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .logo-name2{
         font-size: 18px;
      }
      .logo-name3{
         text-align: center;
      }
    }
  }

  /deep/.md-list{
      >li{
        >a{
          margin: 0 !important;
          padding-left: 15px;
        }
        >a:hover{
         background-color: rgb(21, 21, 36);;
        }
      }
    .fg{
      border-bottom: 1px rgb(40, 40, 53) solid;
      padding-bottom: 20px;
    }
  }
  /deep/.mini-md-list{
    >li{
      >a{
        margin: 0 !important;
        /*padding:0;*/
        display: flex;
        justify-content: center;
      }
    }
  }
</style>
