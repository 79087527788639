<template>
  <div id="app">
    <notifications></notifications>
    <router-view />
  </div>
</template>

<script>
  import kayak from '@/frame/kayak.js'

  export default {
    name: 'App',
    data: function() {
      return {}
    },
    created() {
      kayak.app = this;
    }
  }
</script>

<style>
  body {
    padding: 0;
    margin: 0;
  }

  #app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    /* -webkit-font-smoothing: antialiased; */
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
    width: 100%;
    /* background-color: #3fd5be; */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
</style>
