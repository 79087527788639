import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        wsConn: false,
        webSocketConn:false,
        wsConnText:"未连接",
        vpn:{
            vpnList: [
                {
                    value: 2,
                    label: '主网络'
                }, {
                    value: 1,
                    label: '备用网络'
                }
            ],
            checkVpn: 2
        },
        officeNet:{
            list:[
                {label:'直连办公网络',value:'-1'},{label:'深圳办公网络',value:'2'},{label:'北京办公网络',value:'3'},{label:'成都办公网络',value:'4'}
            ],
            check:''
        },
        loginUser:{},
        skipUrl:""
    },
    getters: {},
    mutations: {
        setWsConn(state, wsConn) {
            state.wsConn = wsConn
        },
        setWebSocketConn(state, webSocketConn){
            state.webSocketConn = webSocketConn
        },
        setWsConnText(state, wsConnText){
            state.wsConnText = wsConnText
        },
        setLoginUser(state, loginUser){
            state.loginUser = loginUser
        },
        setVpn(state, checkVpn){
            state.vpn.checkVpn = checkVpn
        },
        setOfficeNet(state,check){
            state.officeNet.check = check
        },
        setSkipUrl(state,url){
            state.skipUrl=url
        }
    },
    actions: {},
    modules: {},
    plugins: [
        createPersistedState({
            storage: window.sessionStorage
        }),
    ],
})
