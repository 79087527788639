import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import VueMaterial from "vue-material";

import Icon from 'vue2-svg-icon/Icon.vue'
import '@/frame/utils.js';

import Chartist from "chartist";

import VueQuillEditor from 'vue-quill-editor'
// require styles 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import store from '@/store'

// import echarts from 'echarts'

import "vue-material/dist/vue-material.min.css";

import kayakPlugin from "@/frame/kayakPlugin.js"

import router from './router'

import VuevideoPlayer from 'vue-video-player';

require('video.js/dist/video-js.css')

require('vue-video-player/src/custom-theme.css')

const hls =require("videojs-contrib-hls")

Vue.use(hls)


Vue.use(VuevideoPlayer)


Vue.use(VueQuillEditor);
Vue.use(ElementUI, {size: 'small'});
Vue.use(kayakPlugin);
Vue.use(router);

Vue.use(VueMaterial);




Vue.config.productionTip = false;
Vue.component('icon', Icon);

Vue.prototype.$Chartist = Chartist;

router.beforeEach((to, from, next) => {
  if (to.meta.requireLogin) {
    next()
    // kayak.getLoginStatus().then((result) => {
    //   if (result.user !== null) {
    //     window.localStorage.setItem('User', JSON.stringify(result.user));
    //     next()
    //   } else {
    //     next({path: '/login'})
    //   }
    // })
  } else {
    next()
  }
});

new Vue({
  el: '#app',
  router,
  store,
  components: {App},
  template: '<App/>'
});

